import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-7 gap-2 mt-4" }
const _hoisted_2 = { class: "col-span-5 program-detail-card mb-4 relative" }
const _hoisted_3 = { class: "cards-title ml-4 mt-10" }
const _hoisted_4 = { class: "mb-4 px-2 grid grid-cols-9 items-center justify-around" }
const _hoisted_5 = { class: "col-span-9 mt-4 justify-center flex" }
const _hoisted_6 = {
  class: "layers",
  viewBox: "-100 -45 350 120",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_7 = ["d", "fill", "filter"]
const _hoisted_8 = ["d"]
const _hoisted_9 = ["d"]
const _hoisted_10 = ["y1", "y2"]
const _hoisted_11 = ["y"]
const _hoisted_12 = ["y"]
const _hoisted_13 = ["y1", "y2"]
const _hoisted_14 = ["href"]
const _hoisted_15 = ["y"]
const _hoisted_16 = { class: "col-span-5 border-t" }
const _hoisted_17 = { class: "grid grid-flow-col divide-x" }
const _hoisted_18 = {
  key: 0,
  class: "py-3 pl-6 flex flex-col"
}
const _hoisted_19 = { class: "font-medium text-black" }
const _hoisted_20 = {
  key: 1,
  class: "py-3 pl-6 flex flex-col"
}
const _hoisted_21 = { class: "font-medium text-black" }
const _hoisted_22 = {
  key: 2,
  class: "py-3 pl-6 flex flex-col"
}
const _hoisted_23 = { class: "font-medium text-black" }
const _hoisted_24 = {
  key: 3,
  class: "py-3 pl-6 flex flex-col"
}
const _hoisted_25 = { class: "col-span-2 program-detail-card mb-4" }
const _hoisted_26 = { class: "grid grid-rows-7 h-full" }
const _hoisted_27 = {
  key: 0,
  class: "row-span-3 flex border-b"
}
const _hoisted_28 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_29 = { class: "flex justify-between border-y text-xs" }
const _hoisted_30 = { class: "font-bold text-right" }
const _hoisted_31 = { class: "italic text-xs font-light" }
const _hoisted_32 = {
  key: 0,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_33 = { class: "font-bold text-right" }
const _hoisted_34 = {
  key: 1,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_35 = { class: "font-bold text-right inline-flex" }
const _hoisted_36 = {
  key: 1,
  class: "row-span-3 flex border-b"
}
const _hoisted_37 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_38 = { class: "flex justify-between border-y text-xs" }
const _hoisted_39 = { class: "font-bold text-right" }
const _hoisted_40 = { class: "italic text-xs font-light" }
const _hoisted_41 = {
  key: 0,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_42 = { class: "font-bold text-right" }
const _hoisted_43 = {
  key: 1,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_44 = { class: "font-bold text-right inline-flex" }
const _hoisted_45 = {
  key: 2,
  class: "row-span-3 flex border-b"
}
const _hoisted_46 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_47 = { class: "flex justify-between border-y text-xs" }
const _hoisted_48 = { class: "font-bold text-right" }
const _hoisted_49 = { class: "italic text-xs font-light" }
const _hoisted_50 = {
  key: 0,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_51 = { class: "font-bold text-right" }
const _hoisted_52 = {
  key: 1,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_53 = { class: "font-bold text-right inline-flex" }
const _hoisted_54 = {
  key: 3,
  class: "row-span-3 flex"
}
const _hoisted_55 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_56 = {
  key: 0,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_57 = { class: "font-bold text-right" }
const _hoisted_58 = {
  key: 1,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_59 = { class: "font-bold text-right" }
const _hoisted_60 = {
  key: 2,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_61 = { class: "font-bold text-right" }
const _hoisted_62 = {
  key: 3,
  class: "flex justify-between border-y text-xs"
}
const _hoisted_63 = { class: "font-bold text-right" }

import { ref,computed } from 'vue';
import AIConfidenceIcon from './AIConfidenceIcon.vue';
import { calcThickness } from '@/composables/useLayerThicknessToVisualThickness';

const startingPoint = 50;
const inclination = 18;


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatSandwichRenderer',
  props: ['content'],
  setup(__props) {

const props = __props;

// Create a computed property to modify and return the entire content
const sandwich = computed(() => {
  // Clone the entire content object to avoid mutating the prop directly
  const contentClone = { ...props.content };

  // Modify the compositions array within the cloned content
  contentClone.composition = contentClone.composition.map((item,index) => ({
    ...item,
    offset: index * baseOffset.value,
    thickness: item.thickness ?? calcThickness(item.gsm ?? 1)>=10 ? 10 : calcThickness(item.gsm ?? 1),
    color: colors[index % colors.length],
  }));

  // Return the modified content object
  return contentClone;
});


const colors = ['#1c70b2', '#badfd9', '#5fa2a6', '#d4c7c1', '#2a87d3', '#7d91bc'];
const baseOffset = computed(() => {
  const layersCount = props.content.composition.length;

  if (layersCount < 4) {
    return 14;
  } else if (layersCount < 8) {
    return 12;
  } else {
    return 10;
  }
});
const generatAssetLink = (assetLink) =>{
    
    if(!assetLink) return null;

    return '/technology-assets-repo/'+assetLink;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(sandwich.value.sample), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createElementBlock("svg", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sandwich.value.composition, (layer, index) => {
              return (_openBlock(), _createElementBlock("g", {
                key: index,
                class: "layer"
              }, [
                _createElementVNode("g", {
                  style: _normalizeStyle(`--offset-y:-${layer.offset}px;`)
                }, [
                  _createElementVNode("path", {
                    d: `M0,${startingPoint - layer.offset} v-${layer.thickness} L50,${startingPoint - layer.offset - layer.thickness - inclination} L100,${startingPoint - layer.offset - layer.thickness} v${layer.thickness} L50,${startingPoint - layer.offset + inclination} Z`,
                    fill: layer.color,
                    filter: layer.color
                  }, null, 8, _hoisted_7),
                  _createElementVNode("path", {
                    d: `M0,${startingPoint - layer.offset} v-${layer.thickness} L50,${startingPoint - layer.offset + inclination - layer.thickness} v${layer.thickness} Z`
                  }, null, 8, _hoisted_8),
                  _createElementVNode("path", {
                    d: `M50,${startingPoint - layer.offset + inclination} v-${layer.thickness} L100,${startingPoint - layer.offset - layer.thickness} v${layer.thickness} Z`
                  }, null, 8, _hoisted_9)
                ], 4),
                _createElementVNode("line", {
                  x1: -10,
                  y1: startingPoint - layer.offset - (layer.thickness / 2),
                  x2: -100,
                  y2: startingPoint - layer.offset - (layer.thickness / 2),
                  stroke: "black",
                  "stroke-width": "0.1",
                  "stroke-dasharray": "3,6"
                }, null, 8, _hoisted_10),
                _createElementVNode("text", {
                  x: -100,
                  y: startingPoint - layer.offset - (layer.thickness / 2) + -1
                }, _toDisplayString(layer.layerType), 9, _hoisted_11),
                (layer.gsm !== null)
                  ? (_openBlock(), _createElementBlock("text", {
                      key: 0,
                      x: -10,
                      y: startingPoint - layer.offset - (layer.thickness / 2) + -1,
                      "text-anchor": "end"
                    }, _toDisplayString(layer.gsm) + " gsm", 9, _hoisted_12))
                  : _createCommentVNode("", true),
                _createElementVNode("line", {
                  x1: 110,
                  y1: startingPoint - layer.offset - (layer.thickness / 2),
                  x2: 250,
                  y2: startingPoint - layer.offset - (layer.thickness / 2),
                  stroke: "black",
                  "stroke-width": "0.1",
                  "stroke-dasharray": "3,6"
                }, null, 8, _hoisted_13),
                _createElementVNode("a", {
                  href: generatAssetLink(layer.assetLink),
                  target: "_blank",
                  class: _normalizeClass({'disabled cursor-not-allowed': !layer.assetLink})
                }, [
                  _createElementVNode("text", {
                    x: 110,
                    y: startingPoint - layer.offset - (layer.thickness / 2) + -1
                  }, _toDisplayString(layer.layerName), 9, _hoisted_15)
                ], 10, _hoisted_14)
              ]))
            }), 128))
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          (sandwich.value.gsmTotal)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("p", _hoisted_19, _toDisplayString(sandwich.value.gsmTotal) + " GSM", 1),
                _cache[0] || (_cache[0] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "total grammage", -1))
              ]))
            : _createCommentVNode("", true),
          (sandwich.value.gsmPaper)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createElementVNode("p", _hoisted_21, _toDisplayString(sandwich.value.gsmPaper) + " GSM", 1),
                _cache[1] || (_cache[1] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "paper grammage", -1))
              ]))
            : _createCommentVNode("", true),
          (sandwich.value.paperContent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createElementVNode("p", _hoisted_23, _toDisplayString(sandwich.value.paperContent) + " %", 1),
                _cache[2] || (_cache[2] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "Paper content", -1))
              ]))
            : _createCommentVNode("", true),
          (sandwich.value.sealingSides)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, _cache[3] || (_cache[3] = [
                _createElementVNode("p", { class: "font-medium text-black" }, ".", -1),
                _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "sealing sides", -1)
              ])))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, [
        (sandwich.value.otr.min)
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "OTR")
              ], -1)),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "VALUE:", -1)),
                  _createElementVNode("span", _hoisted_30, [
                    _createTextVNode(_toDisplayString(sandwich.value.otr.min) + " ", 1),
                    (sandwich.value.otr.max)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" - " + _toDisplayString(sandwich.value.otr.max), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_31, "  " + _toDisplayString(sandwich.value.otr.unit), 1)
                  ])
                ]),
                (sandwich.value.otr.condition)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONDITION:", -1)),
                      _createElementVNode("span", _hoisted_33, _toDisplayString(sandwich.value.otr.condition), 1)
                    ]))
                  : _createCommentVNode("", true),
                (sandwich.value.otr.confidence)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONFIDENCE:", -1)),
                      _createElementVNode("span", _hoisted_35, [
                        _createVNode(AIConfidenceIcon, {
                          confidence: sandwich.value.otr.confidence
                        }, null, 8, ["confidence"]),
                        _createTextVNode("   " + _toDisplayString(sandwich.value.otr.confidence), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (sandwich.value.wvtr.min)
          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "WVTR")
              ], -1)),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _cache[8] || (_cache[8] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "VALUE:", -1)),
                  _createElementVNode("span", _hoisted_39, [
                    _createTextVNode(_toDisplayString(sandwich.value.wvtr.min) + " ", 1),
                    (sandwich.value.wvtr.max)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" - " + _toDisplayString(sandwich.value.wvtr.max), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_40, "  " + _toDisplayString(sandwich.value.wvtr.unit), 1)
                  ])
                ]),
                (sandwich.value.wvtr.condition)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                      _cache[9] || (_cache[9] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONDITION:", -1)),
                      _createElementVNode("span", _hoisted_42, _toDisplayString(sandwich.value.wvtr.condition), 1)
                    ]))
                  : _createCommentVNode("", true),
                (sandwich.value.wvtr.confidence)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONFIDENCE:", -1)),
                      _createElementVNode("span", _hoisted_44, [
                        _createVNode(AIConfidenceIcon, {
                          confidence: sandwich.value.wvtr.confidence
                        }, null, 8, ["confidence"]),
                        _createTextVNode("   " + _toDisplayString(sandwich.value.wvtr.confidence), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (sandwich.value.heatSeal.min)
          ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "HS")
              ], -1)),
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("div", _hoisted_47, [
                  _cache[12] || (_cache[12] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "VALUE:", -1)),
                  _createElementVNode("span", _hoisted_48, [
                    _createTextVNode(_toDisplayString(sandwich.value.heatSeal.min) + " ", 1),
                    (sandwich.value.heatSeal.max)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" - " + _toDisplayString(sandwich.value.heatSeal.max), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_49, "  " + _toDisplayString(sandwich.value.heatSeal.unit), 1)
                  ])
                ]),
                (sandwich.value.heatSeal.condition)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                      _cache[13] || (_cache[13] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONDITION:", -1)),
                      _createElementVNode("span", _hoisted_51, _toDisplayString(sandwich.value.heatSeal.condition), 1)
                    ]))
                  : _createCommentVNode("", true),
                (sandwich.value.heatSeal.confidence)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                      _cache[14] || (_cache[14] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "CONFIDENCE:", -1)),
                      _createElementVNode("span", _hoisted_53, [
                        _createVNode(AIConfidenceIcon, {
                          confidence: sandwich.value.heatSeal.confidence
                        }, null, 8, ["confidence"]),
                        _createTextVNode("   " + _toDisplayString(sandwich.value.heatSeal.confidence), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (sandwich.value.coatingTechnique.technique)
          ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
              _cache[20] || (_cache[20] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                _createElementVNode("p", {
                  class: "-rotate-90 font-medium text-black",
                  title: "Last Coating Technique"
                }, "LCT")
              ], -1)),
              _createElementVNode("div", _hoisted_55, [
                (sandwich.value.coatingTechnique.speed)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                      _cache[16] || (_cache[16] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "SPEED:", -1)),
                      _createElementVNode("span", _hoisted_57, _toDisplayString(sandwich.value.coatingTechnique.speed), 1)
                    ]))
                  : _createCommentVNode("", true),
                (sandwich.value.coatingTechnique.meltingTemperature)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                      _cache[17] || (_cache[17] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "TEMPERATURE:", -1)),
                      _createElementVNode("span", _hoisted_59, _toDisplayString(sandwich.value.coatingTechnique.meltingTemperature), 1)
                    ]))
                  : _createCommentVNode("", true),
                (sandwich.value.coatingTechnique.meltPressure)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                      _cache[18] || (_cache[18] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "PRESSSURE:", -1)),
                      _createElementVNode("span", _hoisted_61, _toDisplayString(sandwich.value.coatingTechnique.meltPressure), 1)
                    ]))
                  : _createCommentVNode("", true),
                (sandwich.value.coatingTechnique.technique)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                      _cache[19] || (_cache[19] = _createElementVNode("span", { class: "lowercase text-gray-400" }, "TECHNIQUE:", -1)),
                      _createElementVNode("span", _hoisted_63, _toDisplayString(sandwich.value.coatingTechnique.technique), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})