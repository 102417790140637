<template>
    <div class="fixed flex items-end right-8 top-8 z-50">
        <div class="action-icons">
            <div class="round-action-icon" @click="handleBack"> 
                <ArrowUturnLeftIcon class="icon icon-small"  />
            </div> 
            <div v-if="showReset" class="round-action-icon" @click="handleReset"> 
                <ArrowPathIcon  class="icon icon-small" />
            </div>
            <div v-if="isInProgress" class="round-action-icon" @click="handleStop"> 
                <StopCircleIcon class="icon icon-small heartbeat" />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import {computed} from "vue";
import { ArrowUturnLeftIcon, ArrowPathIcon, StopCircleIcon } from '@heroicons/vue/24/outline';
import router from '@/router';
import { useStore } from 'vuex';
import {v4 as uuid} from 'uuid';

const store = useStore();

const handleBack = () => {
  // router.back();
  // store.commit("aiModule/setSubscriptionId",uuid());
  store.commit("aiModule/resetStore",true,uuid());
  store.dispatch("aiModule/subscribe");  
};
const handleReset = () => {
  store.commit("aiModule/setSubscriptionId",uuid());
  store.commit("aiModule/resetStore");
  store.dispatch("aiModule/subscribe");
};
const handleStop = () => {store.dispatch("aiModule/stopSubscription");};


const isStarted = computed(()=> store.state.aiModule.isStarted);
const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);
const typing = computed(()=> store.state.aiModule.typing);

const isCompleted = computed(()=> store.getters["aiModule/getIsCompleted"]);

const isInProgress = computed(()=>{
    return (isSubscriptionActive.value || typing.value) && !isCompleted.value;
});
const showReset = computed(()=>{
    return (!isSubscriptionActive.value || !typing.value) && isStarted.value;

});
</script>
<style scoped>
@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.6);
  }
}

.heartbeat {
  animation: heartbeat 0.4s infinite alternate;
}
</style>